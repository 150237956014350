<template>
    <div>
        <v-list class="filter_left background_color_transparent pb-0 mb-0 px-0 mx-0">
            <v-list-group :ripple="false" v-model="group" :group="groupName" class="px-0">
                <template v-slot:activator class="px-0">
                    <v-list-item-title :ripple="false" class="main_filter">{{$t(title)}}</v-list-item-title>
                </template>
                <v-list-item :ripple="false" v-for="(item, i) in items" :key="i" link :class="item.route.name === $route.name ? 'menu_active_guide' : ''">
                    <v-list-item-title v-text="item.title" class="cursor-pointer" @click="goToMenu(item.route)"></v-list-item-title>
                    <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                </v-list-item>
            </v-list-group>
        </v-list>
        <div class="filter_hr"></div>
    </div>
</template>

<script>
export default {
    name: 'FilterList',
    props: {
        items: {
            type: Array,
            required: true,
        },
        groupName: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        group: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    methods: {
        goToMenu(route) {
            this.$router.push(route);
        },
    },
};
</script>

<style scoped>
/* Добавьте стили, если необходимо */
</style>
